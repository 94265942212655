.about-page {
  min-height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0px max(10px, 10%);

  h1 {
    font-size: 50px;
    font-weight: 700;
    text-shadow: 0px 4px 4px #00000040;
    text-align: center;
  }

  p {
    font-size: 22px;
    font-weight: 400;
    margin-bottom: 5px;
    text-align: center;
  }

  @media (max-width: 767px) {
    h1 {
      width: 100%;
      font-size: 40px;
      text-align: center;
    }
    p {
      width: 100%;
      font-size: 15px;
      text-align: center;
    }
  }

  .about-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  .person-bio {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 50px;
    width: 50%;
    max-width: 500px;
    @media (max-width: 767px) {
      width: 100%;
      padding: 10px 5px;
    }
    img {
      width: 200px;
      border-radius: 100%;
      padding: 5px;
      margin-bottom: 10px;
      @media (max-width: 767px) {
        width: 50%;
      }
    }
    p {
      font-size: 18px;
      text-align: center;
      @media (max-width: 767px) {
        font-size: 14px;
      }
    }
  }
}
