.button {
  border-radius: 14px;
  padding: 12px 25px;
  text-align: center;
  font-size: 17px;
  font-weight: 700;
  margin: 2px;
  cursor: pointer;
  transition: transform 200ms, background-color 200ms;
  user-select: none;
  box-shadow: 0px 2px 6px 3px #c5c5c56a;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  flex: 1;
  display: flex;

  &:hover {
    transform: scale(1.03);
    transition: transform 200ms;
  }

  &:active {
    transform: scale(0.97);
    transition: transform 100ms;
  }

  @media (max-width: 767px) {
    padding: 10px 22px;
    font-size: 14px;
  }
}

.primary-button {
  @extend .button;
  color: white;
  background-color: #5e80af;
}

.secondary-button {
  @extend .button;
  background-color: white;
  color: #5e80af;
}

.icon-button {
  width: 57px;
  height: 57px;
  margin-left: 5px;
}

.button-icon {
  width: 28px;
  height: 28px;
  margin: 0 -16px;
  padding: 0;
}
