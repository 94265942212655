.loading-spinner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  .loading-spinner {
    width: 45px;
    height: 45px;
    border-radius: 15px;
    border: #1c3e6f solid 5px;
    animation: spin ease 700ms infinite forwards;
  }
  p {
    text-align: center;
    font-size: 16px;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(90deg);
  }
}
