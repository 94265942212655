.navbar-container {
  z-index: 10;
  position: fixed;
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
}

.navbar-link {
  font-size: 18px;
  border-radius: 7px;
  background-color: #ffffff57;
  backdrop-filter: blur(3px);
  padding: 8px 15px;
  margin: 10px 2px 10px 0px;
  color: black;

  transition: transform 300ms, background-color 400ms;
  &:hover {
    transform: scale(1.05);
  }
}
.navbar-link-selected {
  transition: transform 300ms, background-color 400ms;
  background-color: #c4d4f5;
  color: #3b3b3b;
}
